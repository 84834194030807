import React from 'react';
import Gallery from '@tra-sg/gatsby-theme-c360-portal/src/components/Gallery';

const PipelinePage = (props) => (
  <Gallery
    metaTitle="Pipeline | c360"
    title="Pipeline"
    dashboardName="pipeline-page"
    pageComponent={props}
  />
);

export default PipelinePage;
